<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_contents">
				<div class="mem_contents_header">
					<div class="title"><span class="mem_info"></span><p>회원가입</p></div>
					<div class="reg-step"><img src="/images/mem/process_bar_01.png" alt="절차"/></div>
				</div>
				<div class="mem_contents_body">
					<div class="body-top"><hr/><p>하이프로는 업계의 검증된 인재와 프로젝트를 연결 해 드립니다.</p><hr/></div>
					<div class="body-middle">
						<!-- 기술인재 가입 -->
						<div class="mem-reg-bt01" @click="pageMove('11')">
							<div class="bt_title">기술인재 가입하기<img src="/images/mem/point_img.png" alt="포인트이미지"/></div>
							<div class="bt_text">경력, 학력 등 기술신고서가 업데이트 되면<br/>포인트가 지급됩니다!</div>
						</div>
						<!-- 수요기업 가입 -->
						<div class="mem-reg-bt02" @click="pageMove('2')">
							<div class="bt_title">수요기업 가입하기</div>
							<div class="bt_text">경력별, 직무별 전문 기술인재를 검색해 보세요!<br/>인재예약도 가능합니다!</div>
						</div>
						<!-- 공급기업 가입 -->
						<div class="mem-reg-bt03" @click="pageMove('31')">
							<div class="bt_title">공급기업 가입하기</div>
							<div class="bt_text">하이프로와 인력 공유를 통하여<br/>훨씬 더 많은 사업 기회를 잡으세요!</div>
						</div>
					</div>
				</div>
				<div class="body-bottom">
					<div class="bottom_text">※회원유형에 따라 가입 절차에 차이가 있습니다. &apos;기술인재&apos;, &apos;수요기업&apos;, &apos;공급기업&apos; 중 선택해 등록하시길 바랍니다.</div>
				</div>
			</div><!-- //mem_contents -->
		</div><!-- //mem_container -->
	</div><!-- //main-contents -->
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods : {
		pageMove(mberDiv) {
			var sendData = {
				mberDivCd : mberDiv
			}

			var name = 'MEM004M01';
			if(mberDiv == '2') name = 'MEM003M02';

			this.$router.push({name : name, params : {forwardData : sendData}});
		}
	}
}
</script>